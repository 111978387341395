import { Permission } from '@api/types/permission';
import { ReactComponent as RcaIndicatorIcon } from '@assets/svgs/analysis-sidebar.svg';
import { ReactComponent as GroupsIndicatorIcon } from '@assets/svgs/groups.svg';
import { ReactComponent as CompanyProfileIndicatorIcon } from '@assets/svgs/company-profile.svg';
import AppLogo from '@assets/svgs/app-logo.svg';
import { CircleButton } from '@components/buttons';
import { Divider } from '@components/layout-util-components/divider';
import { Gap } from '@components/layout-util-components/gap';
import MenuItemProfile from '@components/menu-items/app/menu-item-profile';
import MenuItemRow from '@components/menu-items/app/menu-item-row';
import NotificationMenuItemWrapper from '@components/notifications/notification-menu-item-wrapper';
import { faBars, faHome, faListCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppTr } from '@i18n/use-app-tr';
import { Link, styled } from '@mui/material';
import { sidebarState } from '@store/sidebar-state-manager';
import { useAppSelector } from '@store/store';
import { usePermissions } from '@store/user/permission-hook';
import { selectIsLoggedInAndFullyAuthenticated } from '@store/user/user-selectors';
import { useIsPath } from '@util/path-util';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCompanyProfileLayout from './company-profile-layout-hook';

const SideNav = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  zIndex: 9999,
  position: 'absolute',
  width: isOpen ? 275 : 80,
  height: '100vh',
  padding: isOpen ? '14px 0 0px 0' : '14px 15px 0px 15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '0 0 auto',
  backgroundColor: 'white',
  transition: 'all 0.2s ease-in-out',
  '@media (max-width:768px)': {
    position: 'fixed',
    left: isOpen ? 0 : '-80px',
    zIndex: 100,
    paddingTop: 70,
    boxShadow: '0 0 10px rgba(0,0,0,0.25)',
  },

  '& > .nav-top': {
    width: '100%',
    height: '100px',
    position: 'relative',
    transition: 'all 0.2s ease-in-out',
    flexShrink: 0,

    '@media (max-width:768px)': {
      position: 'fixed',
      borderBottom: '1px solid #D8DBE3',
      left: 0,
      top: 0,
      width: '100%',
      zIndex: 101,
      textAlign: 'center',
      background: '#fff',
      height: 80,
    },
  },

  '& .nav-logo': {
    transition: 'all 0.2s ease-in-out',
    width: isOpen ? 90 : 50,
    height: isOpen ? 90 : 50,
    position: 'absolute',
    left: isOpen ? 36 : 0,
    top: isOpen ? 8 : 3,
    zIndex: 3,
    marginBottom: 10,
    '@media (max-width:768px)': {
      width: 52,
      height: 52,
    },
  },

  '& .menu-item-row': {
    height: 48,
    width: isOpen ? null : 48,
    borderRadius: 8,
    borderLeft: isOpen ? null : 0,
    paddingLeft: 14,
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 5,
    svg: {
      width: isOpen ? 31 : 'auto',
    },
  },

  '& .menu-item-descriptor': {
    display: 'block',
    overflow: 'hidden',
    opacity: isOpen ? '1' : '0',
    width: isOpen ? '100%' : '0px',
    transition: 'all 0.2s ease-in-out',
    '& p': {
      width: 155,
    },
  },

  '& .menu-item-profile': {
    padding: isOpen ? '15px 30px' : '15px 0',
    '& .name-container': {
      width: isOpen ? 'auto' : 0,
      display: isOpen ? 'block' : 'none',
    },
  },
}));

const ButtonWrapper = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  position: 'absolute',
  zIndex: 4,
  left: isOpen ? 214 : 10,
  top: 62,
  transition: 'all 0.2s ease-in-out',
  '@media (max-width:768px)': {
    right: 'auto',
    left: 30,
    top: 0,
    paddingTop: 22.5,
  },
}));

const NavItemsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 0 auto',
  gap: 8,
});

const LowerNavItemsContainer = styled('div')({
  display: 'flex',
  padding: '25px 0',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 'auto',
  '>.menu-item': {
    padding: '12px 10px',
  },
});

export default function AppSidebar() {
  const { t } = useAppTr('common');
  const navigate = useNavigate();
  const { onCompanyProfileClick, canSeeProfilePage } =
    useCompanyProfileLayout();
  const [isSidebarOpen, setIsSidebarOpen] = useState(sidebarState.getState());
  const notificationButtonRef = useRef<HTMLDivElement>(null);
  const outletContainerRef = useRef<HTMLDivElement>(null);

  const isDashboard = useIsPath(['/', '/dashboard', '/home']);
  const isAnalysis = useIsPath('/analysis', { startsWith: true });
  const isGroups = useIsPath('/groups', { startsWith: true });
  const isCompanyProfile = useIsPath('/company-profile', { startsWith: true });
  const isTasks = useIsPath('/tasks', { startsWith: true });

  const canSeeAnalysis = usePermissions([Permission.useCaseFunctionality]);

  const canSeeGroups = usePermissions([
    Permission.manageMyGroups,
    Permission.manageCompanyGroups,
  ]);

  const canSeeTasks = usePermissions([
    Permission.manageMyTasks,
    Permission.manageCompanyTasks,
  ]);

  const isFullyAuthenticated = useAppSelector(
    selectIsLoggedInAndFullyAuthenticated
  );

  useEffect(() => {
    return sidebarState.subscribe(setIsSidebarOpen);
  }, []);

  const toggleSidebar = () => {
    sidebarState.toggle();
  };

  const handleLogoClick = (e: React.MouseEvent) => {
    if (isDashboard) {
      e.preventDefault();
      outletContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <SideNav className="app-sidebar" isOpen={isSidebarOpen}>
      <div className="nav-top">
        <Link href="/" onClick={handleLogoClick}>
          <img className="nav-logo" src={AppLogo} alt="logo" />
        </Link>
        <ButtonWrapper isOpen={isSidebarOpen}>
          <CircleButton onClick={toggleSidebar} width={30} height={30}>
            <FontAwesomeIcon icon={faBars} />
          </CircleButton>
        </ButtonWrapper>
      </div>
      <Gap size={32} />
      <NavItemsContainer>
        <MenuItemRow
          onClick={() => navigate('/')}
          isSelected={isDashboard}
          icon={<FontAwesomeIcon icon={faHome} />}
        >
          {t('navBarItems.dashboard')}
        </MenuItemRow>
        {canSeeAnalysis && (
          <MenuItemRow
            onClick={() => navigate('/analysis')}
            isSelected={isAnalysis}
            icon={<RcaIndicatorIcon width={28} height="auto" />}
          >
            {t('navBarItems.analysis')}
          </MenuItemRow>
        )}
        {canSeeGroups && (
          <MenuItemRow
            onClick={() => navigate('/groups')}
            isSelected={isGroups}
            icon={<GroupsIndicatorIcon width={28} height="auto" />}
          >
            Groups
          </MenuItemRow>
        )}
        {canSeeProfilePage && (
          <MenuItemRow
            onClick={onCompanyProfileClick}
            isSelected={isCompanyProfile}
            icon={<CompanyProfileIndicatorIcon width={28} height="auto" />}
          >
            {t('navBarItems.company')}
          </MenuItemRow>
        )}
        {canSeeTasks && (
          <MenuItemRow
            onClick={() => navigate('/tasks')}
            isSelected={isTasks}
            icon={<FontAwesomeIcon icon={faListCheck} />}
          >
            {t('navBarItems.tasks')}
          </MenuItemRow>
        )}
      </NavItemsContainer>
      <LowerNavItemsContainer>
        <Divider
          compact
          sx={{
            marginBottom: 2.5,
            height: '1px',
            background: 'rgba(0, 0, 0, 0.001)',
          }}
        />
        {isFullyAuthenticated && (
          <>
            <NotificationMenuItemWrapper buttonRef={notificationButtonRef} />
            {/* <Gap size={8} /> */}
          </>
        )}
        {/* <MenuItemRow
            onClick={() => navigate('/help-centre')}
            icon={<FontAwesomeIcon icon={faLifeRing} />}
            isSelected={isHelpCentre}
          >
            {t('navBarItems.help')}
          </MenuItemRow> */}
        <Divider
          compact
          isLineVisible={true}
          sx={{
            marginTop: 2.3,
            marginBottom: -2.5,
            height: '1px',
            background: 'rgba(0, 0, 0, 0.001)',
          }}
        />
      </LowerNavItemsContainer>

      <MenuItemProfile isSideDrawerOpen={isSidebarOpen} />
    </SideNav>
  );
}
