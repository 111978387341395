import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectIsStoragePanelOpen,
  selectStorageNodes,
} from '@store/rca-editor/selectors';
import { useCasePermission } from '@hooks/case/use-case-permission';
import { toggleStoragePanelOpen } from '@store/rca-editor/rca-editor-slice';
import useUser from '@store/user/user-hook';

export default function useChartStoragePanel() {
  const { canEditGraph, isReadOnly, hasDirectAccess } = useCasePermission();
  const { companyUserId } = useUser();
  const isOpen = useAppSelector(selectIsStoragePanelOpen);
  const storageNodes = useAppSelector(selectStorageNodes);
  const dispatch = useAppDispatch();

  const toggleIsOpen = () => dispatch(toggleStoragePanelOpen());

  const allowDragAndDrop = canEditGraph;
  const allowAddNode = !isReadOnly || hasDirectAccess;

  const allowEditNode = (nodeCreatedByCompanyUserId: number) => {
    if (!allowAddNode) return false;

    if (isReadOnly) {
      return nodeCreatedByCompanyUserId === companyUserId;
    }

    return true;
  };

  return {
    isOpen,
    toggleIsOpen,
    storageNodes,
    allowDragAndDrop,
    allowAddNode,
    allowEditNode,
  };
}

export type ChartStoragePanelState = ReturnType<typeof useChartStoragePanel>;
