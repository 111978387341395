import { createSelector } from '@reduxjs/toolkit';
import { RootState, store } from '@store/store';
import { getIncomers, getOutgoers } from 'reactflow';
import {
  NodeCollapseState,
  RcaNode,
  RcaNodeType,
} from '@store/rca-editor/types';
import {
  ChartDisplayMode,
  RcaChartMode,
  RcaEditorState,
} from '@store/rca-editor/rca-editor-slice';
import { RcaUtil } from '@util/rca-util';
import chainApi from '@api/endpoints/chain/chain.api';
import {
  selectIsLensesSlideOutOpen,
  selectIsReportsOpen,
  selectRunReportData,
  selectSelectedRankRow,
} from '@store/reports/reports-selectors';
import { ReportUtil } from '@util/report-util';
import { ReportCellType } from '@api/types/case/case-report/run-report.response';
import {
  DYNAMIC_BRAND_COLOR_ID,
  getColorBrandId,
  getColorForId,
  getLightenedColorForId,
  getOnColorBrandId,
  STATIC_BRAND_COLOR_ID,
} from '@util/colour-identifiers';
import { selectCurrentCompanyUserId } from '@store/user/user-selectors';
import getNodeDisplayMaxBadges = RcaUtil.getNodeDisplayMaxBadges;

const selectEditorState = (state: RootState): RcaEditorState => state.rcaEditor;

export const selectIsDevMode = (state: RootState) => state.rcaEditor.isDevMode;

export const selectIsGeneratingAChainItem = (state: RootState) =>
  state.rcaEditor.isGeneratingAChainItem;

export const selectOnDragOriginalParentId = (state: RootState) =>
  state.rcaEditor.onDragOriginalParentId;

export const selectIsHealthScorePanelOpen = (state: RootState) =>
  state.rcaEditor.isHealthScorePanelOpen;

export const selectIsHelpPanelOpen = (state: RootState) =>
  state.rcaEditor.isHelpPanelOpen;

export const selectIsStoragePanelOpen = (state: RootState) =>
  state.rcaEditor.isStoragePanelOpen;

export const selectHoverVisibilityNodeId = (state: RootState) =>
  state.rcaEditor.hoverVisibilityNodeId;

export const selectDraggingNodeDescendants = (state: RootState) =>
  state.rcaEditor.onDragDescendantIds;

export const selectStorageGraphNode = (state: RootState) =>
  state.rcaEditor.storageGraphNode;

export const selectIsHighlightMode = (state: RootState) =>
  state.rcaEditor.isHighlightMode;

export const selectIsEditingAnyNode = (state: RootState) =>
  state.rcaEditor.editNodeContentId != null;

export const selectHasFocusedNode = (state: RootState) =>
  state.rcaEditor.selectedNodeId != null;

export const selectChartDisplayMode = (state: RootState) =>
  state.rcaEditor.displayMode;

export const selectShouldUseNodeDataForHealthScore = (state: RootState) =>
  state.rcaEditor.useNodeDataHealthScore;

export const selectCurrentUserCaseRole = (state: RootState) =>
  state.rcaEditor.currentUserCaseRole;

export const selectCurrentUserDirectAccess = (state: RootState) =>
  state.rcaEditor.isDirectAccess;

// Before using this selector, make sure to check if the node is focused
export const selectChainId = (state: RootState) =>
  state.rcaEditor.caseDetail?.chainId;

export const selectCurrentRcaCaseId = (state: RootState) =>
  state.rcaEditor.caseDetail!.caseId;

export const selectCurrentRcaCaseIdNullable = (state: RootState) =>
  state.rcaEditor.caseDetail?.caseId;

const selectChainEditorId = (state: RootState) =>
  state.rcaEditor.caseDetail?.chainEditorCompanyUserId;

export const selectCurrentRcaCaseDetail = (state: RootState) =>
  state.rcaEditor.caseDetail;

export const selectIsUserChainEditor = createSelector(
  [selectChainEditorId, selectCurrentCompanyUserId],
  (id, companyUserId) => {
    if (id == null || companyUserId == null) {
      return false;
    }

    return id === companyUserId;
  }
);

export const selectCurrentRcaCurrency = (state: RootState) =>
  state.rcaEditor.caseDetail?.currency ?? '£';

export const selectCurrentDraggingNodeId = (state: RootState) =>
  state.rcaEditor.currentNodeDraggingId || state.rcaEditor.storageGraphNode?.id;

export const selectDisplayHealthState = (state: RootState) =>
  state.rcaEditor.displayHealthState ?? false;

export const selectNodePanelEditorSelectedTab = (state: RootState) =>
  state.rcaEditor.nodePanelEditorTab;

export const selectIsDraggingIntoStorageContainer = (state: RootState) =>
  !!state.rcaEditor.isDraggingIntoStorageContainer;

export const selectNodePanelEditorShouldDefaultToCreate = (state: RootState) =>
  state.rcaEditor.defaultToCreate ?? false;

export const selectEditingNodeId = (state: RootState) =>
  state.rcaEditor.editNodeContentId;

export const selectRcaChartMode = (state: RootState) => state.rcaEditor.mode;

export const selectIsAnyPanelOpen = createSelector(
  [
    selectIsLensesSlideOutOpen,
    selectIsReportsOpen,
    selectIsHealthScorePanelOpen,
  ],
  (lenses, reports, health) => {
    return lenses || reports || health;
  }
);

export const selectStorageNodes = createSelector(
  [selectEditorState],
  (state) => state.storage
);

export const selectProximityEdge = createSelector(
  [selectEditorState],
  (state) => state.proximityEdge
);

export const selectNodes = createSelector(
  selectEditorState,
  (state) => state.nodes
);

export const selectHasAnyHighlightedNodes = createSelector(
  [selectNodes],
  (nodes) => nodes.filter((x) => !!x.data.highlight).length > 0
);

export const selectFocusedNodeIdNullable = createSelector(
  [(state: RootState) => state.rcaEditor.selectedNodeId, selectNodes],
  (id, nodes) => {
    return nodes.find((n) => n.id === id)?.id;
  }
);

// To be used only when known there is a focused node as we are not checking for null
export const selectFocusedNode = createSelector(
  [(state: RootState) => state.rcaEditor.selectedNodeId, selectNodes],
  (id, nodes) => {
    return nodes.find((n) => n.id === id)!;
  }
);

export const selectFocusedNodeNullable = createSelector(
  [(state: RootState) => state.rcaEditor.selectedNodeId, selectNodes],
  (id, nodes) => {
    return nodes.find((n) => n.id === id);
  }
);

export const selectFocusedNodeChainItemId = createSelector(
  [(state: RootState) => state.rcaEditor.selectedNodeId, selectNodes],
  (id, nodes) => {
    return nodes.find((n) => n.id === id)?.data.chainItemId;
  }
);

export const makeSelectNodeFromChainItemId = (chainItemId?: number) =>
  createSelector([selectNodes], (nodes) => {
    if (chainItemId == null) {
      return;
    }

    return nodes.find(
      (node) =>
        node.data.chainItemId === chainItemId &&
        node.type !== RcaNodeType.connection
    );
  });

export const selectIsGraphBusy = (state: RootState) =>
  !!state.rcaEditor.globalBusyTracker;

export const makeSelectIsNodeBusy = (nodeId: string) =>
  createSelector(
    [(state: RootState) => state.rcaEditor.nodeBusyTracker[nodeId]],
    (busy) => !!busy
  );

export const makeSelectHighlightAsConnectedNode = (nodeId: string) =>
  createSelector(
    [makeSelectNode(nodeId)],
    (node) => (node?.data?.linkedFromChainItems?.length ?? 0) > 0
  );

export const selectMetaNodes = createSelector(selectNodes, (nodes) =>
  nodes.filter(RcaUtil.isMetaNode)
);

export const selectCauseNodes = createSelector(selectNodes, (nodes) =>
  nodes.filter(RcaUtil.isCauseNode)
);

export const selectEdges = createSelector(
  selectEditorState,
  (state) => state.edges
);

export const makeSelectNode = (nodeId: string) =>
  createSelector([selectNodes], (nodes) => {
    return nodes.find((n) => n.id === nodeId);
  });

export const makeSelectParentNode = (childNodeId: string) =>
  createSelector([selectNodes, selectEdges], (nodes, edges) => {
    const childNode = nodes.find((n) => n.id === childNodeId);
    if (childNode == null) {
      return undefined;
    }

    const incomingNodes = getIncomers(childNode, nodes, edges);
    if (incomingNodes == null || incomingNodes.length === 0) {
      return undefined;
    }

    return incomingNodes[0] as RcaNode;
  });

export const makeSelectGrandfatherNode = (childNodeId: string) =>
  createSelector(
    [selectNodes, selectEdges, makeSelectParentNode(childNodeId)],
    (nodes, edges, immediateParent) => {
      if (immediateParent == null) {
        return undefined;
      }

      const incomingNodes = getIncomers(immediateParent, nodes, edges);
      if (incomingNodes == null || incomingNodes.length === 0) {
        return undefined;
      }

      return incomingNodes[0] as RcaNode;
    }
  );

export const makeSelectNonMetaParentNode = (childNodeId: string) =>
  createSelector(
    [makeSelectParentNode(childNodeId), makeSelectGrandfatherNode(childNodeId)],
    (parent, grandfather) => {
      if (grandfather == null) {
        return parent;
      }
      if (parent == null) {
        return;
      }
      if (RcaUtil.isMetaNode(parent)) {
        return grandfather;
      }
      return parent;
    }
  );

export const makeSelectNonMetaChildNodes = (nodeId: string) =>
  createSelector(
    [makeSelectNode(nodeId), selectNodes, selectEdges],
    (node, nodes, edges) => {
      if (node == null) {
        return [];
      }

      const outGoers = Array.from(
        getOutgoers(node, nodes, edges).values()
      ) as RcaNode[];
      if (outGoers == null || outGoers.length === 0) {
        return [];
      }

      const ret: Array<RcaNode> = [];
      for (const outGoer of outGoers) {
        if (RcaUtil.isMetaNode(outGoer)) {
          const childNodesOfMetaNode = makeSelectNonMetaChildNodes(outGoer.id)(
            store.getState()
          );
          ret.push(...childNodesOfMetaNode);
        } else {
          ret.push(outGoer);
        }
      }

      return ret;
    }
  );

export const makeSelectParentConnectingEdge = (childNodeId: string) =>
  createSelector([selectEdges], (edges) => {
    return edges.filter((e) => e.target === childNodeId)[0];
  });

export const makeSelectChildNodes = (
  parentNodeId: string,
  allowMetaNodes: boolean = false
) =>
  createSelector([selectNodes, selectEdges], (nodes, edges): Array<RcaNode> => {
    const parentNode = nodes.find((n) => n.id === parentNodeId);
    if (parentNode == null) {
      return [];
    }

    const outGoingNodes = getOutgoers(parentNode, nodes, edges);
    if (outGoingNodes == null) {
      return [];
    }

    return (Array.from(outGoingNodes.values()) as RcaNode[]).filter(
      (x) => allowMetaNodes || !RcaUtil.isMetaNode(x)
    );
  });

// Returns immediate children (normal and meta nodes)
// AND meta node children

export type NormalGroupedNodes = {
  type: 'normal';
  node: RcaNode;
  isConnection: boolean;
};

export type MetaGroupedNodes = {
  type: 'meta';
  node: RcaNode;
  children: RcaNode[];
};

export type GroupedNode = NormalGroupedNodes | MetaGroupedNodes;
export const makeSelectChildNodesWithSubMetaChildNodes = (
  parentNodeId: string
) =>
  createSelector(
    [selectNodes, selectEdges],
    (nodes, edges): Array<GroupedNode> => {
      const parentNode = nodes.find((n) => n.id === parentNodeId);
      if (parentNode == null) {
        return [];
      }

      const outGoingNodes = getOutgoers(parentNode, nodes, edges);
      if (outGoingNodes == null) {
        return [];
      }

      const immediateChildren = Array.from(outGoingNodes.values()) as RcaNode[];

      const ret: Array<GroupedNode> = [];
      for (const child of immediateChildren) {
        if (RcaUtil.isMetaNode(child)) {
          const childNodes = makeSelectChildNodes(
            child.id,
            false
          )(store.getState());

          ret.push({
            type: 'meta',
            node: child,
            children: childNodes,
          });
        } else {
          ret.push({
            type: 'normal',
            node: child,
            isConnection: child.type === RcaNodeType.connection,
          });
        }
      }

      return ret;
    }
  );

export const makeSelectAlLAncestors = (
  nodeId: string,
  allowMetaNodes: boolean = true
) =>
  createSelector(
    [makeSelectNode(nodeId), selectNodes, selectEdges],
    (node, nodes, edges) => {
      if (node == null) {
        return [];
      }

      const ancestors: Array<RcaNode> = [];

      let currentNode = node;
      let parentNode: RcaNode | undefined = undefined;
      do {
        parentNode = getIncomers(currentNode, nodes, edges)?.[0] as
          | RcaNode
          | undefined;

        if (
          !allowMetaNodes &&
          parentNode != null &&
          RcaUtil.isMetaNode(parentNode)
        ) {
          parentNode = getIncomers(parentNode, nodes, edges)?.[0] as
            | RcaNode
            | undefined;
        }

        if (parentNode != null) {
          ancestors.push(parentNode);
          currentNode = parentNode;
        }
      } while (parentNode != null && !parentNode.data.isRoot);

      return ancestors;
    }
  );

export const makeSelectAlLDescendants = (
  parentId: string,
  allowMetaNodes: boolean = false
) =>
  createSelector([selectEditorState], (_) => {
    const descendants: Array<RcaNode> = [];

    const childNodes = makeSelectChildNodes(parentId, true)(store.getState());
    descendants.push(...childNodes);

    for (const childNode of childNodes) {
      const childrenOfChildren = makeSelectAlLDescendants(
        childNode.id,
        true
      )(store.getState());
      descendants.push(...childrenOfChildren);
    }

    return descendants.filter((x) => allowMetaNodes || !RcaUtil.isMetaNode(x));
  });

export const makeSelectNodeFamilyCountInfo = (
  nodeId: string,
  parentId?: string
) =>
  createSelector(
    [makeSelectChildNodes(nodeId, true), makeSelectChildNodes(parentId ?? '')],
    (children, parentChildren) => {
      const metaNodeCount = children.filter(RcaUtil.isMetaNode).length;

      return {
        childCount: children.length - metaNodeCount,
        childCountIncludingMetaNodes: children.length,
        siblingCount: parentChildren.length,
        defaultNodeChildCount: children.filter(
          (x) =>
            x.type === null ||
            x.type === RcaNodeType.default ||
            x.type === RcaNodeType.endState
        ),
      };
    }
  );

export const makeSelectChildCount = (
  nodeId: string,
  allowMetaNodes: boolean = false
) =>
  createSelector(
    [makeSelectChildNodes(nodeId, allowMetaNodes)],
    (nodes) => nodes.length
  );

export const makeSelectDescendantCount = (nodeId) =>
  createSelector(
    [makeSelectAlLDescendants(nodeId)],
    (descendants) => descendants.length
  );
export const makeSelectParentNodeType = (nodeId: string) =>
  createSelector(
    [makeSelectParentNode(nodeId)],
    (parentNode) => parentNode?.type
  );

export const makeSelectNodeCollapsedState = (nodeId: string) =>
  createSelector(
    [
      makeSelectNode(nodeId),
      makeSelectNonMetaParentNode(nodeId),
      makeSelectAlLAncestors(nodeId),
      selectHoverVisibilityNodeId,
      selectDraggingNodeDescendants,
    ],
    RcaUtil.getNodeCollapsedState
  );

export const makeSelectParentOrChildrenCollapsedState = (nodeId: string) =>
  createSelector(
    [
      makeSelectNonMetaParentNode(nodeId),
      makeSelectNonMetaChildNodes(nodeId),
      makeSelectAlLAncestors(nodeId),
      selectHoverVisibilityNodeId,
      selectDraggingNodeDescendants,
    ],
    (parent, children, ancestors, hoverVisibilityNodeId, descendants) => {
      for (const child of children) {
        const state = RcaUtil.getNodeCollapsedState(
          child,
          parent,
          ancestors,
          hoverVisibilityNodeId,
          descendants
        );

        if (state !== NodeCollapseState.default) {
          return state;
        }
      }

      return NodeCollapseState.default;
    }
  );

export const makeSelectOutgoingEdges = (nodeId: string) =>
  createSelector([selectEdges], (edges) => {
    return edges.filter((e) => e.source === nodeId);
  });

export const selectDragHolderNode = createSelector([selectNodes], (nodes) => {
  return nodes.find((n) => n.type === 'drag-holder');
});

export const selectRootNode = createSelector([selectNodes], (nodes) => {
  return nodes.find((node) => node.data.isRoot)!;
});

export const selectHighlightedNodeCount = createSelector(
  [selectNodes],
  (nodes) => {
    return nodes.filter((x) => x.data.highlight).length;
  }
);

export const makeSelectIsDescendantOfMetaNode = (nodeId: string) =>
  createSelector([makeSelectAlLAncestors(nodeId)], (ancestors) => {
    return ancestors.some(RcaUtil.isMetaNode);
  });

export const makeSelectConnectionChildNodes = (nodeId: string) =>
  createSelector([makeSelectNonMetaChildNodes(nodeId)], (nodes) => {
    return nodes.filter((x) => x.type === RcaNodeType.connection);
  });

export const makeSelectConnectionNode = (chainItemId: number) =>
  createSelector([selectNodes], (nodes) => {
    return nodes.find(
      (node) =>
        node.type === RcaNodeType.connection &&
        node.data.chainItemId === chainItemId
    );
  });

export const makeSelectConnectionNodesFor = (nodeId: string) =>
  createSelector([makeSelectNode(nodeId), selectNodes], (node, nodes) => {
    const chainItemId = node?.data.chainItemId;
    if (chainItemId == null) {
      return [];
    }

    return nodes.filter(
      (x) =>
        x.type === RcaNodeType.connection && x.data.chainItemId === chainItemId
    );
  });

export const makeSelectChainItemHealthScore = (
  chainId: number,
  chainItemId: number
) =>
  createSelector(
    [chainApi.endpoints.getChainMetaData.select(chainId)],
    ({ data }) => {
      return data?.healthScore?.chainItems?.[chainItemId];
    }
  );

const makeSelectNodeLookUpData = (chainId: number, chainItemId?: number) =>
  createSelector(
    [chainApi.endpoints.getChainMetaData.select(chainId)],
    ({ data: metaData }) => {
      if (chainItemId == null) {
        return {
          isLoading: true,
        };
      }

      const nodeHealthScore = metaData?.healthScore?.chainItems?.[chainItemId];
      const nodeThemes = metaData?.themes?.[chainItemId];
      const nodeEvidence = metaData?.evidence?.[chainItemId];
      const nodeSolutions = metaData?.solutions?.[chainItemId];
      const nodeType = metaData?.types?.[chainItemId];

      return {
        isLoading: nodeHealthScore == null,
        healthScore: nodeHealthScore,
        themes: nodeThemes ?? [],
        evidence: nodeEvidence ?? [],
        solutions: nodeSolutions ?? [],
        nodeType,
      };
    }
  );

export type NodeDisplayBadge = {
  rank: number;
  baseColor: string;
  indicatorColor?: string;
  text: string | undefined;
  textColor?: string;
};

export const makeSelectNodeDisplayProperties = (
  nodeId: string,
  chainId: number,
  chainItemId?: number
) =>
  createSelector(
    [
      makeSelectNode(nodeId),
      makeSelectNodeLookUpData(chainId, chainItemId),
      makeSelectNodeCollapsedState(nodeId),
      selectDisplayHealthState,
      selectRunReportData,
      selectSelectedRankRow,
      selectChartDisplayMode,
      selectShouldUseNodeDataForHealthScore,
      selectRcaChartMode,
      selectHasAnyHighlightedNodes,
      selectFocusedNodeIdNullable,
    ],
    (
      node,
      lookUpData,
      collapsedState,
      shouldDisplayHealthState,
      data,
      selectedRankRowId,
      chartDisplayMode,
      useNodeDataForHealthScore,
      chartMode,
      hasHighlightedNodes,
      focusedNodeId
    ) => {
      if (node == null || chainItemId == null) {
        return {
          type: 'none',
          isLoading: true,
          chartDisplayMode,
        };
      }

      const hasFocusedNode = focusedNodeId != null;
      const isFocused = focusedNodeId === nodeId;

      const badges: NodeDisplayBadge[] = [];
      const metaData = data?.metaData;
      if (
        data != null &&
        metaData != null &&
        ReportUtil.isCoverageMetaData(metaData)
      ) {
        let outlineColor: string | undefined;
        let faded = selectedRankRowId != null;
        for (let i = 0; i < metaData.length; i++) {
          if (
            ReportUtil.isInChainItemsMetaData(
              chainItemId,
              metaData[i].chainItems
            )
          ) {
            const row = data.data[i];
            const rowData = row.cells.find(
              (x) => x.type === ReportCellType.identifier
            );

            const baseColor = row.colourLight;
            const indicatorColor = row.colour;
            if (selectedRankRowId != null) {
              if (selectedRankRowId === row.clientGeneratedId) {
                outlineColor = indicatorColor;
                faded = false;
              }
            }

            badges.push({
              baseColor,
              indicatorColor,
              rank: metaData[i].rank,
              text: rowData?.value,
            });
          }
        }

        return {
          type: 'coverage',
          badges: getNodeDisplayMaxBadges(badges),
          outlineColor,
          faded,
        };
      }

      const isPresenterMode = chartMode === RcaChartMode.present;
      let faded =
        isPresenterMode &&
        !node!.data.highlight &&
        hasHighlightedNodes &&
        !node.selected;

      const healthScore = useNodeDataForHealthScore
        ? node!.data?.healthScore
        : lookUpData.healthScore;
      if (shouldDisplayHealthState) {
        return {
          type: 'health-score',
          outlineColor:
            hasFocusedNode && isFocused
              ? undefined
              : RcaUtil.getHealthScoreColorFromScore(healthScore ?? -1),
          healthScore,
          isLoading: lookUpData.isLoading,
          faded: hasFocusedNode && !isFocused,
        };
      }

      let lookupData: Record<number, string> | string | undefined;
      let emptyLabel: string | undefined;
      let badgeColor,
        indicatorColor,
        outlineColor,
        badgeTextColor: string | undefined;

      if (!node.data.isRoot && !useNodeDataForHealthScore) {
        switch (chartDisplayMode) {
          case ChartDisplayMode.theme:
            const data = {};
            if (lookUpData.themes) {
              lookUpData.themes.forEach((themeResource) => {
                data[themeResource.colourId] = themeResource.name;
              });
            }
            lookupData = data;
            emptyLabel = 'No Theme';
            // Themes will use the same outline as evidence
            outlineColor = getColorForId('evidence');
            badgeTextColor = getColorBrandId(1);
            break;
          case ChartDisplayMode.solutions:
            lookupData = lookUpData.solutions;
            emptyLabel = 'No Solution';
            badgeColor = getLightenedColorForId('solution');
            outlineColor = getColorForId('solution');
            badgeTextColor = indicatorColor = '#FFFFFF';
            break;
          case ChartDisplayMode.evidence:
            lookupData = lookUpData.evidence;
            emptyLabel = 'No Evidence';
            badgeColor = getLightenedColorForId('evidence');
            outlineColor = getColorForId('evidence');
            badgeTextColor = indicatorColor = '#FFFFFF';
            break;
          case ChartDisplayMode.static:
          case ChartDisplayMode.dynamic:
            emptyLabel = lookupData = lookUpData.nodeType;
            const isStatic = lookupData === 'Static';
            const isDynamic = lookupData === 'Dynamic';
            const id = isStatic
              ? STATIC_BRAND_COLOR_ID
              : isDynamic
              ? DYNAMIC_BRAND_COLOR_ID
              : -1;
            badgeColor = getColorBrandId(id);
            indicatorColor = badgeTextColor = getOnColorBrandId(id);
            faded =
              (isStatic && chartDisplayMode === ChartDisplayMode.dynamic) ||
              (isDynamic && chartDisplayMode === ChartDisplayMode.static);

            // Outlines will use the dark blue as used in evidence
            outlineColor = getColorForId('evidence');

            // Focused node colour is handled in styled-default-node.tsx
            if (hasFocusedNode && isFocused) {
              outlineColor = undefined;
            }
            break;
        }
      }

      if (isPresenterMode) {
        outlineColor = node.data.highlight ? '#636D73' : undefined;
      }

      if (lookupData != null) {
        if (typeof lookupData === 'string') {
          if (lookupData === 'NA') {
            outlineColor = undefined;
            badges.push({
              rank: -1,
              baseColor: getLightenedColorForId(-1),
              indicatorColor: getColorForId(-1),
              textColor: getColorBrandId(1),
              text: emptyLabel,
            });

            if (!isPresenterMode && !node.selected) {
              faded = true;
            }
          } else {
            badges.push({
              rank: 1,
              baseColor: badgeColor ?? getLightenedColorForId(1),
              indicatorColor: indicatorColor ?? getColorForId(1),
              textColor: badgeTextColor,
              text: lookupData,
            });
          }
        } else {
          for (const [id, name] of Object.entries(lookupData)) {
            badges.push({
              rank: 1,
              baseColor: badgeColor ?? getLightenedColorForId(+id),
              indicatorColor: indicatorColor ?? getColorForId(+id),
              text: name,
              textColor: badgeTextColor,
            });
          }

          if (badges.length === 0) {
            outlineColor = undefined;
            badges.push({
              rank: -1,
              baseColor: getLightenedColorForId(-1),
              indicatorColor: getColorForId(-1),
              textColor: getColorBrandId(1),
              text: emptyLabel,
            });

            if (!isPresenterMode && !node.selected) {
              faded = true;
            }
          }
        }
      }

      if (collapsedState === NodeCollapseState.ghost) {
        faded = true;
      }

      return {
        type: node.data.disproved ? 'disproved' : 'default',
        badges: getNodeDisplayMaxBadges(badges),
        isLoading: !useNodeDataForHealthScore && lookUpData.isLoading,
        healthScore,
        chartDisplayMode,
        outlineColor: node.data.disproved ? '#FF3D3D' : outlineColor,
        highlightColor: node.data.disproved ? '#FFECEC' : undefined,
        faded,
      };
    }
  );
