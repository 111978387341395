import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RcaEdge,
  RcaNode,
  RcaNodeType,
  StorageNode,
} from '@store/rca-editor/types';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  EdgeChange,
  NodeChange,
} from 'reactflow';
import caseApi from '@api/endpoints/case.api';
import { CaseResource } from '@api/types/case/case.resource';
import chainApi from '@api/endpoints/chain/chain.api';
import dayjs from 'dayjs';
import { ChainDetailResponse } from '@api/types/chain/chain-detail.response';
import { SnapshotState } from '@store/rca-graph-saver/rca-graph-saver-slice';
import { RcaUtil } from '@util/rca-util';
import caseOutcomeApi from '@api/endpoints/case-outcome.api';
import chainItemApi from '@api/endpoints/chain/chain-item.api';
import { CaseRole } from '@api/types/case-role/case-role-option';

export enum NodePanelEditorTab {
  overview,
  evidence,
  tasks,
  impacts,
  notes,
  solutions,
  connections,
  activity,
}

export enum RcaChartMode {
  build,
  present,
}

export enum ChartDisplayMode {
  healthScore,
  theme,
  evidence,
  solutions,
  static,
  dynamic,
}

export interface RcaEditorState {
  nodes: Array<RcaNode>;
  edges: Array<RcaEdge>;
  storageNodeIdsToConsume: Array<string>;
  storage: Array<StorageNode>;
  selectedNodeId?: string;
  nodePanelEditorTab: NodePanelEditorTab;
  defaultToCreate?: boolean;
  mode: RcaChartMode;
  proximityEdge?: RcaEdge;
  storageGraphNode?: RcaNode;

  isGeneratingAChainItem: boolean;
  editNodeContentId?: string;
  currentNodeDraggingId?: string;
  onDragOriginalParentId?: string;
  onDragDescendantIds?: Array<string>;
  hoverVisibilityNodeId?: string;
  isHighlightMode: boolean;

  displayMode: ChartDisplayMode;
  // When true, the health score will be pulled from the node data
  // instead of the lookup endpoint data, as this won't be available
  // to users viewing an RCA Outcome
  useNodeDataHealthScore: boolean;
  caseDetail?: CaseResource;

  displayHealthState?: boolean;
  isDevMode: boolean;

  nodeBusyTracker: Record<string, number>;
  globalBusyTracker: number;
  isDraggingIntoStorageContainer?: boolean;

  isHealthScorePanelOpen: boolean;
  isHelpPanelOpen: boolean;
  currentUserCaseRole?: CaseRole;
  isDirectAccess?: boolean;

  isStoragePanelOpen: boolean;
}

const initialNodes: Array<RcaNode> = [
  {
    position: { x: 0, y: 0 },
    id: '-1',
    draggable: false,
    data: {
      label: '',
      isRoot: true,
      sortOrder: 0,
    },
  },
];

const initialState: RcaEditorState = {
  nodes: initialNodes,
  edges: [],
  storage: [],
  storageNodeIdsToConsume: [],
  mode: RcaChartMode.build,
  nodePanelEditorTab: NodePanelEditorTab.overview,
  isDevMode: false,
  isHighlightMode: false,
  useNodeDataHealthScore: false,
  nodeBusyTracker: {},
  globalBusyTracker: 0,
  isHealthScorePanelOpen: false,
  isHelpPanelOpen: false,
  displayMode: ChartDisplayMode.healthScore,
  isGeneratingAChainItem: false,
  isStoragePanelOpen: false,
};

export const rcaEditorSlide = createSlice({
  name: 'rca-editor',
  initialState,
  reducers: {
    hardResetRcaToInitialState(state, _: PayloadAction) {
      return initialState;
    },
    resetEditorToSnapshot(state, { payload }: PayloadAction<SnapshotState>) {
      Object.assign(state, payload);
    },
    toggleDevMode(state, _: PayloadAction) {
      state.isDevMode = !state.isDevMode;
    },
    onNodesChange(state, { payload }: PayloadAction<NodeChange[]>) {
      state.nodes = applyNodeChanges(payload, state.nodes) as RcaNode[];
    },
    onEdgesChange(state, { payload }: PayloadAction<EdgeChange[]>) {
      state.edges = applyEdgeChanges(payload, state.edges);
    },
    setDisplayMode(
      state,
      {
        payload: { displayMode, useNodeDataHealthScore = false },
      }: PayloadAction<{
        displayMode: ChartDisplayMode;
        useNodeDataHealthScore?: boolean;
      }>
    ) {
      state.displayMode = displayMode;
      state.useNodeDataHealthScore = useNodeDataHealthScore;
    },
    onConnectNode(state, { payload }: PayloadAction<Connection>) {
      const { target } = payload;

      // Remove any edges that are connected to the target node
      state.edges = state.edges.filter((edge) => edge.target !== target);
      state.edges = addEdge(payload, state.edges);
    },
    refreshNodes(state, { payload }: PayloadAction<RcaNode[]>) {
      state.nodes = payload;
    },
    refreshEdges(state, { payload }: PayloadAction<RcaEdge[]>) {
      state.edges = payload;
    },
    addNodesToStorage(state, { payload }: PayloadAction<RcaNode[]>) {
      const currentCase = state.caseDetail!;
      const newStorageNodes = payload
        .filter(
          (node) => node.type == null || node.type === RcaNodeType.default
        )
        .map(
          (node): StorageNode => ({
            clientUuid: node.id,
            caseId: node.data.caseId,
            chainId: currentCase.chainId,
            caseName: currentCase.name,
            chainItemId: node.data.chainItemId,
            created: dayjs().utc().toISOString(),
            description: node.data.label,
            endState: node.data.endState,
            endStateId: node.data.endStateId,
            themes: [],
          })
        );

      state.storage = [...state.storage, ...newStorageNodes];
      state.storageNodeIdsToConsume = [
        ...state.storageNodeIdsToConsume,
        ...newStorageNodes.map((node) => node.clientUuid),
      ];

      // remove duplicates in state.storageNodeIdsToConsume
      state.storageNodeIdsToConsume = Array.from(
        new Set(state.storageNodeIdsToConsume)
      );
    },
    consumeStorageChainIds(state, { payload }: PayloadAction<Array<string>>) {
      state.storageNodeIdsToConsume = state.storageNodeIdsToConsume.filter(
        (x) => !payload.includes(x)
      );
    },
    setSelectedNode(state, { payload }: PayloadAction<RcaNode | undefined>) {
      const nodeId = payload?.id;
      if (nodeId == null || nodeId !== state.selectedNodeId) {
        state.nodePanelEditorTab = NodePanelEditorTab.overview;
      }
      state.selectedNodeId = nodeId;
      state.editNodeContentId = undefined;
      state.nodes = state.nodes.map((node) => {
        return {
          ...node,
          selected: !!nodeId && node.id === nodeId,
        };
      });
    },
    setEditNodeContentId(state, { payload }: PayloadAction<string>) {
      state.nodes = state.nodes.map((node) => {
        return {
          ...node,
          selected: node.id === payload,
        };
      });
      state.selectedNodeId = undefined;
      state.editNodeContentId = payload;
    },
    resetFocus(state, _: PayloadAction) {
      state.editNodeContentId = undefined;
      state.selectedNodeId = undefined;
      state.nodePanelEditorTab = NodePanelEditorTab.overview;
      state.nodes = state.nodes.map((node) => {
        return {
          ...node,
          selected: false,
        };
      });
    },
    updateNode(state, { payload }: PayloadAction<RcaNode>) {
      state.nodes = state.nodes.map((node) => {
        if (node.id !== payload.id) {
          return node;
        }

        return {
          ...node,
          ...payload,
        };
      });
    },
    updateEdge(state, { payload }: PayloadAction<RcaEdge>) {
      state.edges = state.edges.map((edge) => {
        if (edge.id !== payload.id) {
          return edge;
        }

        return {
          ...edge,
          ...payload,
        };
      });
    },
    setProximityEdge(state, { payload }: PayloadAction<RcaEdge>) {
      if (state.proximityEdge == null) {
        state.proximityEdge = payload;
      } else {
        state.proximityEdge = {
          ...state.proximityEdge,
          ...payload,
        };
      }
    },
    clearProximityEdge(state, _: PayloadAction) {
      state.proximityEdge = undefined;
    },
    setOnDragIds(
      state,
      action: PayloadAction<{
        currentNodeDraggingId: string;
        onDragOriginalParentId: string;
        onDragDescendantIds?: Array<string>;
      }>
    ) {
      const {
        currentNodeDraggingId,
        onDragOriginalParentId,
        onDragDescendantIds,
      } = action.payload;
      state.currentNodeDraggingId = currentNodeDraggingId;
      state.onDragOriginalParentId = onDragOriginalParentId;
      state.onDragDescendantIds = onDragDescendantIds;
    },
    resetOnDragIds(state, _: PayloadAction) {
      state.currentNodeDraggingId = undefined;
      state.onDragOriginalParentId = undefined;
      state.onDragDescendantIds = undefined;
    },
    selectNodePanelEditorTab(
      state,
      { payload }: PayloadAction<NodePanelEditorTab>
    ) {
      state.nodePanelEditorTab = payload;
      state.defaultToCreate = false;
    },
    selectNodePanelEditorForCreateTab(
      state,
      { payload }: PayloadAction<NodePanelEditorTab>
    ) {
      state.nodePanelEditorTab = payload;
      state.defaultToCreate = true;
    },
    setRcaChartMode(state, { payload }: PayloadAction<RcaChartMode>) {
      state.mode = payload;
      state.isHighlightMode = false;
      state.nodes = state.nodes.map((node) => ({
        ...node,
        selected: false,
        data: {
          ...node.data,
          highlight: false,
        },
      }));
      // Make sure the root node is focused so that the presenter bar appears
      if (payload === RcaChartMode.present) {
        state.selectedNodeId = state.nodes.find((x) => !!x.data?.isRoot)?.id;
      } else {
        state.selectedNodeId = undefined;
      }
    },
    unHighlightAllNodes(state, _: PayloadAction) {
      state.nodes = state.nodes.map((node) => ({
        ...node,
        selected: false,
        data: {
          ...node.data,
          highlight: false,
        },
      }));
    },
    setSortedSiblings(state, { payload }: PayloadAction<Array<RcaNode>>) {
      state.nodes = state.nodes.map((node) => {
        const sibling = payload.find((sibling) => sibling.id === node.id);
        return sibling ?? node;
      });
    },
    setHoverVisibilityNodeId(
      state,
      { payload }: PayloadAction<string | undefined>
    ) {
      state.hoverVisibilityNodeId = payload;
    },
    setStorageGraphNode(
      state,
      { payload }: PayloadAction<RcaNode | undefined>
    ) {
      state.storageGraphNode = payload;
    },
    setStorageNodes(state, { payload }: PayloadAction<Array<StorageNode>>) {
      state.storage = payload;
    },
    setHighlightMode(state, { payload }: PayloadAction<boolean>) {
      state.isHighlightMode = payload;
    },
    setDisplayHealthState(state, { payload }: PayloadAction<boolean>) {
      state.displayHealthState = payload;
    },
    incrementNodeBusyTracker(state, { payload }: PayloadAction<string>) {
      state.nodeBusyTracker[payload] = state.nodeBusyTracker[payload] ?? 0;
      state.nodeBusyTracker[payload]++;
    },
    decrementNodeBusyTracker(state, { payload }: PayloadAction<string>) {
      state.nodeBusyTracker[payload] = state.nodeBusyTracker[payload] ?? 0;
      if (state.nodeBusyTracker[payload] !== 0) {
        state.nodeBusyTracker[payload]--;
      }
    },
    incrementGlobalBusyTracker(state, _: PayloadAction) {
      state.globalBusyTracker++;
    },
    decrementGlobalBusyTracker(state, _: PayloadAction) {
      if (state.globalBusyTracker !== 0) {
        state.globalBusyTracker--;
      }
    },
    manuallySetUserCaseRole(state, { payload }: PayloadAction<CaseRole>) {
      state.currentUserCaseRole = payload;
    },
    setChartFromServerData(
      state,
      { payload }: PayloadAction<ChainDetailResponse>
    ) {
      RcaUtil.populateNodesFromServerData(
        state.nodes,
        state.storage,
        payload,
        state.selectedNodeId
      );
    },
    setIsDraggingIntoStorageContainer(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.isDraggingIntoStorageContainer = payload;
    },
    setIsHealthScorePanelOpen(state, { payload }: PayloadAction<boolean>) {
      state.isHealthScorePanelOpen = payload;
    },
    toggleHealthScorePanelOpen(state) {
      state.isHealthScorePanelOpen = !state.isHealthScorePanelOpen;
    },
    setIsHelpPanelOpen(state, { payload }: PayloadAction<boolean>) {
      state.isHelpPanelOpen = payload;
    },
    toggleHelpPanelOpen(state) {
      state.isHelpPanelOpen = !state.isHelpPanelOpen;
    },
    toggleStoragePanelOpen(state) {
      state.isStoragePanelOpen = !state.isStoragePanelOpen;
    },
    setStoragePanelOpen(state, { payload }: PayloadAction<boolean>) {
      state.isStoragePanelOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      caseApi.endpoints.getCaseDetail.matchFulfilled,
      (state, { payload: caseDetail }) => {
        state.caseDetail = caseDetail;
      }
    );
    builder.addMatcher(
      caseApi.endpoints.getCaseDetailsForMe.matchFulfilled,
      (state, { payload: caseDetailForMe }) => {
        state.currentUserCaseRole = caseDetailForMe.caseRole;
        state.isDirectAccess = caseDetailForMe.isDirectAccess;
      }
    );

    builder.addMatcher(
      chainApi.endpoints.getChainDetail.matchFulfilled,
      (state, { payload: chainDetail }) => {
        state.edges = chainDetail.edges;
        state.storage = chainDetail.storage;
        state.selectedNodeId = undefined;

        // Ensure nothing is selected or highlighted
        state.nodes = chainDetail.nodes.map((node) => ({
          ...node,
          selected: false,
          data: {
            ...node.data,
            highlight: false,
          },
        }));
      }
    );

    builder.addMatcher(
      caseOutcomeApi.endpoints.getCaseOutcomeResult.matchFulfilled,
      (state, { payload: outcomeResult }) => {
        const { chain } = outcomeResult;
        if (chain != null) {
          state.nodes = chain.nodes;
          state.edges = chain.edges;
          state.storage = chain.storage;
          state.selectedNodeId = undefined;
        }
      }
    );

    builder.addMatcher(
      chainItemApi.endpoints.createChainItem.matchPending,
      (state) => {
        state.isGeneratingAChainItem = true;
      }
    );

    builder.addMatcher(
      chainItemApi.endpoints.createChainItem.matchFulfilled,
      (state) => {
        state.isGeneratingAChainItem = false;
      }
    );

    builder.addMatcher(
      chainItemApi.endpoints.createChainItem.matchRejected,
      (state) => {
        state.isGeneratingAChainItem = false;
      }
    );
  },
});

export default rcaEditorSlide.reducer;

export const {
  hardResetRcaToInitialState,
  resetEditorToSnapshot,
  setSelectedNode,
  resetFocus,
  setEditNodeContentId,
  updateNode,
  updateEdge,
  refreshEdges,
  refreshNodes,
  onNodesChange,
  onEdgesChange,
  onConnectNode,
  setProximityEdge,
  clearProximityEdge,
  setOnDragIds,
  resetOnDragIds,
  selectNodePanelEditorTab,
  setRcaChartMode,
  addNodesToStorage,
  toggleDevMode,
  setSortedSiblings,
  consumeStorageChainIds,
  setStorageGraphNode,
  setHoverVisibilityNodeId,
  setHighlightMode,
  unHighlightAllNodes,
  setStorageNodes,
  setDisplayHealthState,
  incrementNodeBusyTracker,
  decrementNodeBusyTracker,
  incrementGlobalBusyTracker,
  decrementGlobalBusyTracker,
  setChartFromServerData,
  selectNodePanelEditorForCreateTab,
  setIsDraggingIntoStorageContainer,
  setIsHealthScorePanelOpen,
  toggleHealthScorePanelOpen,
  setDisplayMode,
  manuallySetUserCaseRole,
  setIsHelpPanelOpen,
  toggleHelpPanelOpen,
  toggleStoragePanelOpen,
  setStoragePanelOpen,
} = rcaEditorSlide.actions;
