import { ReactNode, useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { isNullOrEmpty } from '@util/string-util';
import { PopoverOrigin } from '@mui/material';

interface ActionDivider {
  divider?: boolean;
}

interface ActionButton {
  label: ReactNode;
  onClick: () => void;
  icon?: ReactNode;
  hasPermission?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  minWidth?: number;
}

export type PopoverMenuAction = ActionDivider | ActionButton;

export default function useRolloverInfo(
  defaultMessage: string | null = null,
  allowShow = true,
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
  minWidth?: number
) {
  const [id] = useState(uuid());
  const [message, setMessage] = useState<string | null>(defaultMessage);
  const [target, setTarget] = useState<HTMLElement>();
  const [isShown, setIsShown] = useState(false);

  const onMouseOver = useCallback(() => {
    if (isNullOrEmpty(message) || !allowShow) {
      return;
    }

    setIsShown(true);
  }, [allowShow, message]);

  const onMouseLeave = useCallback(() => {
    setIsShown(false);
  }, []);

  const ref = useCallback((element: HTMLElement | null) => {
    if (element == null) {
      setTarget(undefined);
    } else {
      setTarget(element!);
    }
  }, []);

  useEffect(() => {
    if (target != null) {
      target.addEventListener('mouseover', onMouseOver);
      target.addEventListener('mouseleave', onMouseLeave);

      return () => {
        target.removeEventListener('mouseover', onMouseOver);
        target.removeEventListener('mouseleave', onMouseLeave);
      };
    }
  }, [onMouseLeave, onMouseOver, target]);

  return {
    id,
    ref,
    isShown,
    setMessage,
    target,
    message,
    anchorOrigin,
    transformOrigin,
    minWidth,
  };
}

export type RolloverInfoState = ReturnType<typeof useRolloverInfo>;
