import React, { ComponentType } from 'react';
import { Box, styled } from '@mui/material';
import RcaEditorAppBar from '@pages/app/rca/tabs/components/rca-editor-app-bar';
import {
  useGetCaseDetailQuery,
  useGetCaseDetailsForMeQuery,
} from '@api/endpoints/case.api';
import { useParams } from 'react-router-dom';
import { FullScreenLoadingIndicator } from '@components/loading-indicator';
import { EditorPathParams } from '@pages/app/rca/tabs/editor-path-params';
import { ErrorPage } from '@components/error-handling/error-route-catcher';
import useRcaGating, { RcaGatingStatus } from '@hooks/rca-gating-hook';
import HealthScoreSidePanel from '@pages/app/rca/tabs/components/health-score/health-score-side-panel';
import {
  setIsHealthScorePanelOpen,
  setIsHelpPanelOpen,
} from '@store/rca-editor/rca-editor-slice';
import LensesPanel from '@pages/app/rca/tabs/lenses/lenses-panel';
import { setOpenLensesSlideOut } from '@store/reports/reports-slice';
import ReportPanel from '@pages/app/rca/tabs/reports/report-panel';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectCurrentRcaCaseIdNullable,
  selectIsHealthScorePanelOpen,
  selectIsHelpPanelOpen,
} from '@store/rca-editor/selectors';
import { selectIsLensesSlideOutOpen } from '@store/reports/reports-selectors';
import { selectIsNotificationsOpen } from '@store/notifications/selectors';
import NotificationsSidePanel from '@components/notifications/notifications-side-panel';
import { setNotificationsOpen } from '@store/notifications/notifications-slice';
import RCAHelpPanel from '@pages/app/rca/tabs/lenses/rca-help-panel';
import AppSidebar from '@components/layouts/app/app-sidebar';

interface Opt {
  SideBarComponent?: ComponentType;
  containerClassName?: string;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  minHeight: '100vh',
  width: '100vw',
  backgroundColor: '#ECEDF0',
});

const InnerContainer = styled('div')({
  height: 'calc(100vh - 80px)',
  overflowY: 'scroll',
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  // marginLeft: '80px',
});

const ContentContainer = styled('div', {
  target: 'rca-form-scaffold-content-container',
})({
  display: 'flex',
  justifyContent: 'center',
  flex: '1 0 75%',
});

const SidebarContainer = styled('div')({
  position: 'fixed',
  right: 20,
  top: 20,
  backgroundColor: '#E5E5E5',
  flex: '0 1 25%',
});

export const EditorPanels = () => {
  const dispatch = useAppDispatch();

  const caseId = useAppSelector(selectCurrentRcaCaseIdNullable);
  const isHealthScoreOpen = useAppSelector(selectIsHealthScorePanelOpen);
  const isHelpPanelOpen = useAppSelector(selectIsHelpPanelOpen);
  const isLensesOpen = useAppSelector(selectIsLensesSlideOutOpen);
  const isNotificationPanelOpen = useAppSelector(selectIsNotificationsOpen);

  return (
    <>
      {caseId != null && (
        <>
          <HealthScoreSidePanel
            isOpen={isHealthScoreOpen}
            onClose={() => dispatch(setIsHealthScorePanelOpen(false))}
            topMargin={80}
          />
          <LensesPanel
            isOpen={isLensesOpen}
            onClose={() => dispatch(setOpenLensesSlideOut(false))}
          />
          <NotificationsSidePanel
            show={isNotificationPanelOpen}
            onClose={() => {
              dispatch(setNotificationsOpen(false));
            }}
            applyTopMargin
          />
          <RCAHelpPanel
            show={isHelpPanelOpen}
            onClose={() => dispatch(setIsHelpPanelOpen(false))}
          />
          <ReportPanel />
        </>
      )}
    </>
  );
};

const withEditorScaffold =
  (
    Component: ComponentType,
    { SideBarComponent, containerClassName }: Opt = {}
  ) =>
  () => {
    const gating = useRcaGating();
    const { caseId } = useParams<EditorPathParams>();
    const { data, isSuccess, isFetching } = useGetCaseDetailQuery(
      caseId != null ? +caseId : 0,
      {
        skip: caseId == null,
      }
    );

    useGetCaseDetailsForMeQuery(caseId != null ? +caseId : 0, {
      skip: caseId == null,
    });

    if (!isFetching && !isSuccess) {
      return <ErrorPage goTo="/" />;
    }

    if (gating === RcaGatingStatus.tabExpired) {
      return (
        <ErrorPage
          title="Time Out Warning."
          goTo="/"
          buttonMessage="Refresh"
          hideLink
          message="This content has expired due to system being open in another tab/window. Please refresh to reload your content."
        />
      );
    }

    return (
      <Container className={containerClassName}>
        <AppSidebar />
        <RcaEditorAppBar
          caseDetail={data}
          isOffline={gating === RcaGatingStatus.offline}
        />
        {isSuccess ? (
          <InnerContainer className="tabContainer">
            <ContentContainer>
              <Component />
            </ContentContainer>
            {SideBarComponent ? (
              <SidebarContainer>
                <SideBarComponent />
              </SidebarContainer>
            ) : null}
          </InnerContainer>
        ) : (
          <FullScreenLoadingIndicator />
        )}
      </Container>
    );
  };

export default withEditorScaffold;
