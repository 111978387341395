import { Box, Grid, styled, Typography } from '@mui/material';
import Spacer from '@components/layout-util-components/spacer';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectIsDraggingIntoStorageContainer,
  selectIsStoragePanelOpen,
  selectStorageNodes,
} from '@store/rca-editor/selectors';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Column from '@components/layout-util-components/column';
import Row from '@components/layout-util-components/row';
import { Gap } from '@components/layout-util-components/gap';
import { FlatButton } from '@components/buttons';
import useChartStoragePanel from '@pages/app/rca/tabs/components/storage/chart-storage-hook';
import ChartStorageListView from '@pages/app/rca/tabs/components/storage/views/chart-storage-list-view';
import { RcaUtil } from '@util/rca-util';
import { ReactComponent as StorageIcon } from '@assets/svgs/cause-storage.svg';
import { toggleStoragePanelOpen } from '@store/rca-editor/rca-editor-slice';

type StyledProps = {
  expanded: boolean;
  isHoveringOver: boolean;
};

const Container = styled(Column, { target: 'chart-storage' })<StyledProps>(
  ({ theme: { palette }, expanded, isHoveringOver }) => ({
    zIndex: 100,
    position: 'absolute',
    right: 20,
    bottom: 20,
    width: 320,
    gap: 0,
    backgroundColor: 'white',
    border: '1px solid rgba(255,255,255,0.07)',
    borderRadius: 4,
    padding: '10px 15px',
    transition: 'hover 0.2s ease-in-out',
    boxShadow:
      '0px 2px 5px 0px rgba(0, 0, 0, 0.04), 0px 1px 0px 0px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      background: '#F9F4F1',
    },
    '.storage-icon': {
      width: 24,
      marginRight: 5,
    },

    ...(expanded && {
      right: 0,
      bottom: 0,
      top: 0,
      boxShadow: '0px 0px 15px 0px #00000040',
      border: 0,
      borderRadius: 0,
      padding: 20,
      '&:hover': {
        border: 0,
      },
    }),

    ...(isHoveringOver && {
      border: '3px dashed #FF6C00 !important',
      padding: expanded ? 17 : '8px 13px',
      backgroundSize: '30px 1px',
    }),

    '.MuiAccordion-root,.MuiAccordion-rounded': {
      boxShadow: 'none',
      margin: 0,
      padding: 0,

      '.MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',

        '.indicator': {
          width: 32,
          height: 32,
          padding: 8,
          borderRadius: 2,
          background: 'rgba(255,108,0, 0.08)',
          color: palette.primary.main,
        },
      },
    },
  })
);

const StyledIndicatorContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '0 0 auto',
  width: 36,
  height: 36,
  borderRadius: '50%',
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '13px',
  letterSpacing: '0.46px',
  background: 'rgba(255,108,0, 0.08)',
  color: palette.primary.main,
  textAlign: 'center',
}));

const StyledSubTitle = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.common.grey90,
  fontSize: '14px',
  width: '80%',
}));

export default function ChartStorage() {
  const state = useChartStoragePanel();
  const { isOpen, toggleIsOpen } = state;
  const nodes = useAppSelector(selectStorageNodes);
  const isHoveringOver = useAppSelector(selectIsDraggingIntoStorageContainer);

  // const onUpdateNodeDescription = async (
  //   node: StorageNode,
  //   description?: string
  // ) => {
  //   if (!isNullOrEmpty(description)) {
  //     try {
  //       await dispatch(updateStorageNodeDescription(node, description!));
  //       showSuccessMessage('Updated storage item');
  //     } catch (e) {
  //       const { message, errors } = e as ApiError<UpdateStorageItemRequest>;
  //       showErrorMessage(
  //         errors?.chainItemId ??
  //           errors?.chainId ??
  //           errors?.description ??
  //           message
  //       );
  //     }
  //   }
  //   setEditNodeId(undefined);
  // };
  return (
    <>
      {isOpen ? (
        <Container
          ref={RcaUtil.setChartStorageContainer}
          expanded={isOpen}
          isHoveringOver={isHoveringOver}
        >
          <Grid container>
            <Grid item xs={12}>
              <Column>
                <Row>
                  <Typography variant="h2">Cause Stack</Typography>
                  <Spacer />
                  <FlatButton shape="circle" onClick={toggleIsOpen}>
                    <FontAwesomeIcon icon={faTimes} />
                  </FlatButton>
                </Row>
                <Gap size={15} />
                <StyledSubTitle>
                  Add and store cause boxes for future use
                </StyledSubTitle>
              </Column>
            </Grid>
          </Grid>
          <Gap size={20} />
          <ChartStorageListView state={state} />
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}
