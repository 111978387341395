import { PageAlertDisplay } from '@components/alerts';
import BillingAlert from '@components/alerts/billing-alert';
import Column from '@components/layout-util-components/column';
import NotificationsSidePanel from '@components/notifications/notifications-side-panel';
import { styled } from '@mui/material';
import { setNotificationsOpen } from '@store/notifications/notifications-slice';
import { selectIsNotificationsOpen } from '@store/notifications/selectors';
import { sidebarState } from '@store/sidebar-state-manager';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectIsCancelledCompanyStatus,
  selectIsLoggedInAndFullyAuthenticated,
} from '@store/user/user-selectors';
import { COMPANY_BILLING_PATH, useIsPath } from '@util/path-util';
import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useCompanyProfileLayout from './company-profile-layout-hook';
import AppSidebar from './app-sidebar';

const PageContainer = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  backgroundColor: '#F2F2F2',
}));

const OutletContainer = styled('div')<{ isNavOpen: boolean }>(
  ({ isNavOpen }) => ({
    transition: 'all 0.2s ease-in-out',
    flex: '1 0 auto',
    marginLeft: isNavOpen ? 275 : 80,
    width: 'calc(100vw - 275px)',
    overflowY: 'auto',
    '@media (max-width:768px)': {
      marginLeft: 0,
      marginTop: 80,
    },
  })
);

export default function AppLayout() {
  const dispatch = useAppDispatch();
  const { canAccessBilling } = useCompanyProfileLayout();
  const [isSidebarOpen, setIsSidebarOpen] = useState(sidebarState.getState());
  const notificationButtonRef = useRef<HTMLDivElement>(null);
  const outletContainerRef = useRef<HTMLDivElement>(null);

  const isCompanyBillingPath = useIsPath(COMPANY_BILLING_PATH);

  const isCompanyCancelled = useAppSelector(selectIsCancelledCompanyStatus);
  const isNotificationPanelOpen = useAppSelector(selectIsNotificationsOpen);
  const isFullyAuthenticated = useAppSelector(
    selectIsLoggedInAndFullyAuthenticated
  );

  useEffect(() => {
    return sidebarState.subscribe(setIsSidebarOpen);
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    dispatch(setNotificationsOpen(false));
  };

  return (
    <>
      <PageContainer className={isSidebarOpen ? 'nav-open' : 'nav-closed'}>
        <AppSidebar />
        <OutletContainer ref={outletContainerRef} isNavOpen={isSidebarOpen}>
          <Column>
            {canAccessBilling && <BillingAlert />}
            <PageAlertDisplay ignoreError absolute />
          </Column>
          {(!isCompanyCancelled || isCompanyBillingPath) && <Outlet />}
        </OutletContainer>
      </PageContainer>
      {isFullyAuthenticated && (
        <NotificationsSidePanel
          show={isNotificationPanelOpen}
          onClickOutside={handleClickOutside}
          toggleButtonRef={notificationButtonRef}
          onClose={() => {
            dispatch(setNotificationsOpen(false));
          }}
        />
      )}
    </>
  );
}
