import { Box, styled, Typography } from '@mui/material';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

interface Props {
  icon?: ReactElement;
  indicatorContent?: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  className?: string;
  disabled?: boolean;
}

const StyledContainer = styled('div', { target: 'menu-item-row' })<{
  isSelected: boolean;
  disabled?: boolean;
}>(({ isSelected, theme, disabled = false }) => ({
  minWidth: '110px',
  flexDirection: 'column',
  '&:hover': {
    backgroundColor: '#F8F8FA',
  },
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  padding: 10,
  alignItems: 'center',
  gap: 5,
  alignSelf: 'stretch',
  borderRadius: 5,
  width: 215,
  cursor: disabled ? 'default' : 'pointer',
  opacity: disabled ? 0.5 : 1,
  ...(isSelected && {
    cursor: 'default',
    background: '#FF6C001A',
    color: '#001927',

    '&:hover': {
      background: '#fff',
    },
  }),
  '&.selected': {
    '& svg': {
      color: '#666666',
      '& path': {
        fill: '#666666',
      },
    },
    '& svg.fill-white': {
      color: '#FFF',
      '& path': {
        fill: '#FFF',
        stroke: '#666666',
      },
    },
  },
}));

const IconContainer = styled('div')<{ isSelected: boolean }>(
  ({ isSelected, theme }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    width: '20px',
    height: '20px',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      width: 'auto',
      color: '#666666',
      '& path': {
        fill: '#666666',
      },
    },
    '& svg.fill-white': {
      color: '#FFF',
      '& path': {
        fill: isSelected ? '#FFF' : '#F2F2F2',
        stroke: '#666666',
      },
    },
  })
);

const StyledIndicatorContainer = styled('div')(({ theme: { palette } }) => ({
  flex: '0 0 auto',
  background: '#fff',
  width: 18,
  height: 18,
  borderRadius: '50%',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  textAlign: 'center',
  color: '#001927',
}));

export default function MenuItemButton({
  icon,
  indicatorContent,
  onClick,
  isSelected,
  children,
  disabled,
}: PropsWithChildren<Props>) {
  return (
    <StyledContainer
      isSelected={isSelected ?? false}
      onClick={onClick}
      className={isSelected ? 'selected' : ''}
      disabled={disabled}
    >
      {icon ? (
        <IconContainer isSelected={isSelected ?? false}>{icon}</IconContainer>
      ) : null}
      <Box flexGrow={1} className="menu-item-descriptor">
        <Typography
          variant="body1"
          fontSize={16}
          fontWeight={400}
          lineHeight="29px"
          letterSpacing={-0.5}
        >
          {children}
        </Typography>
        {indicatorContent ? (
          <StyledIndicatorContainer>
            {indicatorContent}
          </StyledIndicatorContainer>
        ) : null}
      </Box>
    </StyledContainer>
  );
}
