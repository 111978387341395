import {
  DragEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useState,
} from 'react';
import { PopoverMenuAction } from '@components/popover-menu/use-popover-menu';
import { Box, styled, Typography } from '@mui/material';
import Row from '@components/layout-util-components/row';
import Column from '@components/layout-util-components/column';
import Spacer from '@components/layout-util-components/spacer';
import { Gap } from '@components/layout-util-components/gap';
import { useAppSelector } from '@store/store';
import { selectIsDevMode } from '@store/rca-editor/selectors';
import InitialsContainerWithData from '@components/containers/initials-container-with-data';
import { getInitials } from '@util/name-util';
import RolloverInfo from '@components/rollover-info/rollover-info';
import useRolloverInfo from '@components/rollover-info/use-rollover-info';

const NodeDefaultBody = '/svgs/node-storage-chain.svg';

interface Props {
  onDragStart?: DragEventHandler;
  onDragEnd?: DragEventHandler;
  actions?: Array<PopoverMenuAction>;
  actionButton?: ReactNode;
  onEditDescription?: (value?: string) => void;
  onSubmitDescription?: () => void;
  description?: string;
  chainItemId?: number;
  clientUuid?: string;
  createdByUserName?: string;
  createdByCurrentUser?: boolean;
  odd?: boolean;
}

const Container = styled('div')(({ draggable }) => ({
  flex: '0 0 auto',
  width: '100%',
  height: 95,
  position: 'relative',
  padding: '0px 20px 0px 0px',
  color: 'black',
  cursor: draggable ? 'grab' : undefined,

  'delete-btn': {
    background: 'red',
  },

  '*': {
    zIndex: 1,
  },

  '.node-background': {
    zIndex: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    mask: `url(${NodeDefaultBody}) center center no-repeat`,
    maskSize: 'contain',
    backgroundColor: 'white',
    pointerEvents: 'none',
  },

  textarea: {
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    padding: '13px',
    lineHeight: '1.25',
    width: '100%',
    height: '100%',
    resize: 'none',
    fontFamily: 'Usual',
  },

  'textarea, .description': {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.25',
    padding: '13px',
    fontFamily: 'Usual',
    textAlign: 'left',
    width: '190px',
    border: 0,
  },
  p: {
    fontSize: '13px',
    padding: '13px',
    wordBreak: 'break-word',
    width: '190px',
  },
}));

const ChartStorageNode = forwardRef<string, Props>(
  (
    {
      description,
      actionButton,
      onDragStart,
      onDragEnd,
      onEditDescription,
      onSubmitDescription,
      chainItemId,
      clientUuid,
      createdByUserName,
      createdByCurrentUser,
      odd,
    },
    ref
  ) => {
    const isDevMode = useAppSelector(selectIsDevMode);
    const [isDragging, setIsDragging] = useState(false);
    const usernameRollOver = useRolloverInfo(
      createdByUserName,
      true,
      {
        vertical: 'center',
        horizontal: 50,
      },
      {
        vertical: 'center',
        horizontal: 10,
      },
      0
    );

    const keyEnter: KeyboardEventHandler = (e) => {
      if (!e.shiftKey && e.code === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        onSubmitDescription!();
      }
    };

    return (
      <Container
        draggable={onDragStart != null}
        onDragStart={
          onDragStart != null
            ? (e) => {
                setIsDragging(true);
                onDragStart!(e);
              }
            : undefined
        }
        onDragEnd={
          onDragEnd != null
            ? (e) => {
                setIsDragging(false);
                onDragEnd!(e);
              }
            : undefined
        }
        sx={{ opacity: isDragging ? 0.1 : 1 }}
      >
        <div className="node-background" />
        <Row sx={{ height: '100%' }}>
          <Column sx={{ alignSelf: 'stretch' }}>
            {onEditDescription == null ? (
              <>
                <Typography
                  variant="body1"
                  sx={{
                    maxHeight: '50px',
                    overflow: 'scroll',
                    fontSize: '14px !important',
                  }}
                >
                  {isDevMode
                    ? `chainItemId: ${chainItemId}\n${clientUuid}`
                    : description}
                </Typography>
                <Spacer />
              </>
            ) : (
              <textarea
                autoFocus
                value={description || ''}
                onChange={(e) => onEditDescription(e.target.value)}
                onKeyDown={keyEnter}
                placeholder="Caused By"
              />
            )}
            {createdByUserName && (
              <Box
                ref={usernameRollOver.ref}
                sx={{ marginLeft: '10px', marginBottom: '15px' }}
              >
                <InitialsContainerWithData
                  circleContainerStyles={{
                    backgroundColor:
                      createdByCurrentUser === true ? '#168664' : '#007DBA',
                    color: '#FFF',
                    opacity: 1,
                    fontSize: '12px',
                  }}
                  initials={getInitials(createdByUserName)}
                />
                <RolloverInfo {...usernameRollOver} minWidth={0} />
              </Box>
            )}
          </Column>
          <Spacer />
          {actionButton ? actionButton : null}
        </Row>

        {/*<Box alignSelf="center">*/}
        {/*  {mode === ViewMode.add ? (*/}
        {/*    <FlatButton onClick={() => submit()}>*/}
        {/*      <BinIcon />*/}
        {/*    </FlatButton>*/}
        {/*  ) : mode === ViewMode.edit ? (*/}
        {/*    <Row>*/}
        {/*      <FlatButton onClick={() => submit(value)}>*/}
        {/*        <CheckIcon />*/}
        {/*      </FlatButton>*/}
        {/*      <Gap size={4} />*/}
        {/*      <FlatButton onClick={() => submit()}>*/}
        {/*        <CloseIcon />*/}
        {/*      </FlatButton>*/}
        {/*    </Row>*/}
        {/*  ) : actions != null && actions.length > 0 ? (*/}
        {/*    <ElipsisButton className="actions" actions={actions} />*/}
        {/*  ) : null}*/}
        {/*</Box>*/}
      </Container>
    );
  }
);

export default ChartStorageNode;
