import requireAuth from '@components/hoc/with-required-auth-hoc';
import withEditorScaffold from '@pages/app/rca/tabs/components/rca-editor-scaffold';
import { NodeDragHandler } from 'reactflow';
import { useAppDispatch, useAppSelector } from '@store/store';
import {
  selectChainId,
  selectEdges,
  selectHasFocusedNode,
  selectIsEditingAnyNode,
  selectIsGraphBusy,
  selectNodes,
  selectProximityEdge,
  selectRcaChartMode,
  selectStorageGraphNode,
} from '@store/rca-editor/selectors';
import {
  onConnectNode,
  onEdgesChange,
  onNodesChange,
  RcaChartMode,
  resetFocus,
  setIsHealthScorePanelOpen,
} from '@store/rca-editor/rca-editor-slice';
import React, {
  createContext,
  DragEventHandler,
  MouseEventHandler,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  beginDraggingNode,
  devOnlyToggleDevMode,
  focusNodeAndBringIntoView,
  stopDraggingNode,
  updateProximityEdge,
  updateStorageGraphNodePosition,
} from '@store/rca-editor/rca-editor-actions';
import FocusedNodePanelEditor from '@pages/app/rca/tabs/components/editor-panels/focused-node-panel-editor';
import ChartControls from '@pages/app/rca/tabs/components/chart-controls';
import ChartStorage from '@pages/app/rca/tabs/components/storage/chart-storage';
import chainApi from '@api/endpoints/chain/chain.api';
import ChartBusyIndicator from '@pages/app/rca/tabs/components/chart-busy-indicator';
import { RcaNode } from '@store/rca-editor/types';
import { RcaUtil } from '@util/rca-util';
import ChartCancelAction from '@pages/app/rca/tabs/components/chart-cancel-action';
import { captureInitialEditorSnapshot } from '@store/rca-graph-saver/rca-graph-saver-actions';
import RenderGraph from '@pages/app/rca/tabs/components/graph/render-graph';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { numberFromString } from '@util/string-util';
import { useDebouncedCallback } from 'use-debounce';
import GraphInitialLoadContainer from '@pages/app/rca/tabs/components/graph/graph-initial-load-container';
import { styled } from '@mui/material';
import {
  closeReport,
  setOpenLensesSlideOut,
} from '@store/reports/reports-slice';
import ChartReadonlyAlert from '@pages/app/rca/tabs/components/chart-readonly-alert';

type QueryParams = {
  chainItem?: string;
  panelTab?: string;
};

export const NodeEditingTextAreaContext = createContext<
  RefObject<HTMLTextAreaElement>
>({} as any);

const Container = styled('div')({
  minHeight: 'calc(100vh - 80px)',
  width: 'calc(100vw - 80px)',
  marginLeft: '80px',
});

function RcaEditorChart() {
  const dispatch = useAppDispatch();
  const { chainItem: chainItemQuery, panelTab: panelTabQuery } =
    useSearchParamsTyped<QueryParams>();

  const mouseDownTargetRef = useRef<EventTarget | null>(null);
  const nodeEditingTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const hasDispatchedDragBegin = useRef(false);

  const hasFocusedNode = useAppSelector(selectHasFocusedNode);
  const isBusy = useAppSelector(selectIsGraphBusy);
  const chainId = useAppSelector(selectChainId)!;
  const isReadOnly =
    useAppSelector(selectRcaChartMode) === RcaChartMode.present;
  const nodes = useAppSelector(selectNodes);
  const edges = useAppSelector(selectEdges);
  const storageGraphNode = useAppSelector(selectStorageGraphNode);
  const proximityEdge = useAppSelector(selectProximityEdge);
  const canZoomAndPan = !useAppSelector(selectIsEditingAnyNode);

  const hasAutoFocusedOnMount = useRef(false);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isReactFlowReady, setIsReactFlowReady] = useState(false);

  const onReactFlowClick: MouseEventHandler = (e) => {
    if (mouseDownTargetRef.current !== e.target) {
      const textArea = nodeEditingTextAreaRef.current;
      if (textArea != null) {
        textArea.focus();
        e.stopPropagation();
        e.preventDefault();
      }
      return;
    }

    const className = (e.target as HTMLDivElement)?.className;
    if (
      !isReadOnly &&
      className &&
      className.includes != null &&
      className.includes('react-flow__pane')
    ) {
      dispatch(resetFocus());
    }
  };

  const onDragOver = useDebouncedCallback<DragEventHandler<HTMLDivElement>>(
    (e) => {
      const nodePos = RcaUtil.getNodePosFromCursor(e);
      dispatch(updateStorageGraphNodePosition(nodePos.x, nodePos.y));
    },
    1
  );

  const onDragNode: NodeDragHandler = (e, node) => {
    if (RcaUtil.isDraggable(node as RcaNode)) {
      if (!hasDispatchedDragBegin.current) {
        hasDispatchedDragBegin.current = true;
        dispatch(beginDraggingNode(node as RcaNode));
      } else {
        dispatch(
          updateProximityEdge(node as RcaNode, { x: e.clientX, y: e.clientY })
        );
      }
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onDragNodeStop: NodeDragHandler = (_, node) => {
    if (RcaUtil.isDraggable(node as RcaNode)) {
      if (hasDispatchedDragBegin.current) {
        hasDispatchedDragBegin.current = false;
        dispatch(stopDraggingNode(node as RcaNode));
      }
    }
  };

  useEffect(() => {
    if (hasAutoFocusedOnMount.current || !isReactFlowReady || isChartLoading) {
      return;
    }

    const chainItem = numberFromString(chainItemQuery);
    if (chainItem != null) {
      const node = nodes?.find((n) => n.data.chainItemId === chainItem);
      if (node != null) {
        const panelTab = numberFromString(panelTabQuery);
        dispatch(focusNodeAndBringIntoView(node, panelTab, true));
        hasAutoFocusedOnMount.current = true;
      }
    } else {
      const node = nodes?.find((n) => n.data.isRoot && n.id !== '-1');
      if (node != null) {
        RcaUtil.snapFocusToNode(node, true);
        hasAutoFocusedOnMount.current = true;
      }
    }
  }, [
    isReactFlowReady,
    chainItemQuery,
    dispatch,
    nodes,
    panelTabQuery,
    isChartLoading,
  ]);

  useEffect(() => {
    if (isReactFlowReady) {
      // Ensure panels are closed on unmount
      return () => {
        dispatch(setIsHealthScorePanelOpen(false));
        dispatch(setOpenLensesSlideOut(false));
        dispatch(closeReport());
      };
    }
  }, [dispatch, isReactFlowReady]);

  useEffect(() => {
    const metaDataRequest = dispatch(
      chainApi.endpoints.getChainMetaData.initiate(chainId, {
        subscribe: true,
        forceRefetch: true,
      })
    );

    setIsChartLoading(true);
    const chartDetailRequest = dispatch(
      chainApi.endpoints.getChainDetail.initiate(chainId, {
        subscribe: true,
        forceRefetch: true,
      })
    );

    chartDetailRequest.then(() => {
      setIsChartLoading(false);
      dispatch(captureInitialEditorSnapshot());
    });

    return () => {
      metaDataRequest.unsubscribe();
      chartDetailRequest.unsubscribe();
    };
  }, [chainId, dispatch]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.code === 'F2') {
        dispatch(devOnlyToggleDevMode());
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
  }, [dispatch]);

  return (
    <Container>
      <NodeEditingTextAreaContext.Provider value={nodeEditingTextAreaRef}>
        <RenderGraph
          ref={chartRef}
          onInit={(reactFlow) => {
            RcaUtil.setReactFlowInstance(reactFlow);
            setIsReactFlowReady(true);
          }}
          nodes={storageGraphNode ? [...nodes, storageGraphNode] : nodes}
          edges={proximityEdge ? [...edges, proximityEdge] : edges}
          onNodesChange={(changes) => {
            if (!storageGraphNode) {
              dispatch(onNodesChange(changes));
            }
          }}
          deleteKeyCode={null}
          onEdgesChange={(changes) => dispatch(onEdgesChange(changes))}
          onConnect={(connect) => dispatch(onConnectNode(connect))}
          panOnDrag={canZoomAndPan}
          zoomOnPinch={canZoomAndPan}
          zoomOnDoubleClick={false}
          maxZoom={1.25}
          minZoom={0.25}
          zoomOnScroll={canZoomAndPan}
          onClick={onReactFlowClick}
          onNodeDrag={onDragNode}
          onNodeDragStop={onDragNodeStop}
          onDragOver={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = 'move';
            onDragOver(e);
          }}
          onMouseDownCapture={(e) => {
            mouseDownTargetRef.current = e.target;
          }}
        >
          <ChartCancelAction />

          <ChartControls />
          <ChartReadonlyAlert />
          {!isReadOnly ? <ChartStorage /> : null}
          {isBusy ? <ChartBusyIndicator /> : null}
        </RenderGraph>
        {hasFocusedNode && !isChartLoading ? <FocusedNodePanelEditor /> : null}

        {isChartLoading && <GraphInitialLoadContainer chartRef={chartRef} />}
      </NodeEditingTextAreaContext.Provider>
    </Container>
  );
}

export default requireAuth(withEditorScaffold(RcaEditorChart));
